/* This example requires Tailwind CSS v2.0+ */
import { useRouter } from "../node_modules/next/router";
import { IconType } from "react-icons";
import {
  SiToyota,
  SiNissan,
  SiHyundai,
  SiSuzuki,
  SiKia,
  SiChevrolet,
  SiMitsubishi,
  SiHonda,
  SiBmw,
  SiFord,
  SiVolkswagen,
  SiMercedes,
  SiAudi,
  SiSubaru,
} from "react-icons/si";

const brandsObject: { brandName: string; icon: IconType }[] = [
  { brandName: "Toyota", icon: SiToyota },
  { brandName: "Nissan", icon: SiNissan },
  { brandName: "Hyundai", icon: SiHyundai },
  { brandName: "Suzuki", icon: SiSuzuki },
  { brandName: "Kia", icon: SiKia },
  { brandName: "Chevrolet", icon: SiChevrolet },
  { brandName: "Mitsubishi", icon: SiMitsubishi },
  { brandName: "Honda", icon: SiHonda },
  { brandName: "BMW", icon: SiBmw },
  { brandName: "Ford", icon: SiFord },
  { brandName: "Volkswagen", icon: SiVolkswagen },
  { brandName: "Mercedes", icon: SiMercedes },
  { brandName: "Audi", icon: SiAudi },
  { brandName: "Subaru", icon: SiSubaru },
];

export default function BrandGrid() {
  const router = useRouter();

  const goToList = (brandName: string) => {
    const pathname = `/autosusados?search=${brandName.toLowerCase()}`;
    router.push(pathname);
  };

  return (
    <div className="max-w-7xl px-4 mt-3">
      <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
        Buscá autos usados por marca
      </h2>
      <ul
        role="list"
        className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-2 py-6"
      >
        {brandsObject.map((item, index) => (
          <li
            key={index}
            className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow hover:bg-gray-100 cursor-pointer"
            onClick={() => goToList(item.brandName)}
          >
            <div className="flex-1 flex flex-col p-8 items-center justify-center">
              <div className="h-16 w-28 sm:w-40 relative">
                {<item.icon className=" w-16 h-16 mx-auto text-gray-400" />}
              </div>
              <h3 className="mt-1 text-gray-900 text-sm font-medium">
                {item.brandName}
              </h3>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
